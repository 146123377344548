import { ref } from 'vue';
import { defineStore } from 'pinia';
import { auth } from '@/Firebase/Init';

export const useFirebaseStore = defineStore('firebase', () => {
  const loggedIn = ref(false);
  const accessToken = ref('');

  const logout = async () => {
    await auth.signOut();
    loggedIn.value = false;
    accessToken.value = '';
  };

  const listenAuthStateChanged = (authenticated: Function, unAuthenticated: Function) => {
    auth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        loggedIn.value = false;
        accessToken.value = '';
        unAuthenticated();
        return false;
      }

      loggedIn.value = true;
      accessToken.value = user.accessToken;
      await authenticated();
      return true;
    });
  };

  return {
    listenAuthStateChanged,
    logout,
    loggedIn,
    accessToken
  };
});
