import axios, { type CreateAxiosDefaults } from 'axios';
import { paramsSerializer } from './ParamsSerializer';

import { baseUrl } from '@/Config';

export default class ApiClient {
  static create(config: CreateAxiosDefaults = {}) {
    return axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true,
      withXSRFToken: true,
      paramsSerializer: paramsSerializer,
      ...config
    });
  }
}
