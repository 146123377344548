import type { RouteRecordRaw } from 'vue-router';

const listingsRoutes: RouteRecordRaw[] = [
  {
    path: '/listings',
    component: () => import('./Pages/Main.vue'),
    children: [
      {
        path: '',
        name: 'listings',
        component: () => import('./Pages/Listings.vue'),
        children: [
          {
            path: 'livestock',
            name: 'listingsHome',
            meta: {
              type: 'products_form'
            },
            components: {
              filters: () => import('./Components/Livestock/Filter.vue'),
              topBar: () => import('./Components/TopBar.vue'),
              card: () => import('./Components/Livestock/ItemCard.vue'),
              pagination: () => import('./Components/Pagination.vue')
            }
          },
          {
            path: 'animal-feed',
            name: 'animalFeed',
            meta: {
              type: 'animal_feeds_form'
            },
            components: {
              filters: () => import('./Components/AnimalFeed/Filter.vue'),
              topBar: () => import('./Components/TopBar.vue'),
              card: () => import('./Components/AnimalFeed/ItemCard.vue'),
              pagination: () => import('./Components/Pagination.vue')
            }
          }
        ]
      },
      {
        path: ':id(\\d+)',
        name: 'productDetails',
        component: () => import('./Pages/ProductDetails.vue')
      },
      {
        path: ':pathMatch(.*)*',
        name: 'listingsNotFound',
        component: () => import('@/Modules/Shared/Pages/NotFound.vue')
      }
    ]
  }
];

export default listingsRoutes;
