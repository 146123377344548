import qs from 'qs';

export function paramsSerializer(params: object): string {
  return qs.stringify(cleanParams(params), {
    encode: false,
    arrayFormat: 'brackets',
    skipNulls: true
  });
}

function cleanParams(params: any): any {
  return Object.entries(params).reduce((cleaned: Record<string, any>, [key, value]) => {
    if (value === null || value === '') return cleaned;

    // Not an empty array
    if (Array.isArray(value) && value.length > 0) {
      cleaned[key] = value;
      return cleaned;
    }

    // Not an empty object
    if (typeof value === 'object' && Object.keys(value).length > 0) {
      cleaned[key] = cleanParams(value);
      return cleaned;
    }

    cleaned[key] = value;
    return cleaned;
  }, {});
}
