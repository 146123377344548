import { auth } from '@/Firebase/Init';
import { useFirebaseStore } from '@/Modules/Auth/Stores/FirebaseStore';

import { storeToRefs } from 'pinia';

export async function monitorAuthState() {
  auth.onAuthStateChanged(async user => {
    const firebaseStore = useFirebaseStore();
    const { accessToken, loggedIn } = storeToRefs(firebaseStore);

    loggedIn.value = false;
    accessToken.value = '';

    if (user) {
      loggedIn.value = true;
      accessToken.value = await user.getIdToken();
    }
  });
}
