import type { RouteRecordRaw } from 'vue-router';
import SharedRoutes from '@/Modules/Shared/Routes';
import listingsRoutes from '@/Modules/Listings/Routes';
import { runMiddleware } from '@/Middlewares/runMiddleware';
import type { Middleware } from '@/Middlewares/Middleware';
import { createRouter, createWebHistory } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/Modules/Shared/Pages/MainLayout.vue'),
    children: [
      ...SharedRoutes,
      ...listingsRoutes,
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/Modules/Shared/Pages/NotFound.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const middlewares = (to.meta.middlewares as Middleware[]) || [];
  if (middlewares.length > 0) {
    await runMiddleware(middlewares, to, from, next);
  } else {
    next();
  }
});

export default router;
