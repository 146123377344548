import type { Middleware } from './Middleware';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useAuthStore } from '@/Modules/Auth/Stores/AuthStore';
import { storeToRefs } from 'pinia';

export class AuthMiddleware implements Middleware {
  async run(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    const authStore = useAuthStore();
    const { authenticated } = storeToRefs(authStore);

    if (!authenticated.value) {
      await authStore.checkAuthStatus();
      if (!authenticated.value) {
        next('/login');
      }
    }

    next();
  }
}
