import { api as config } from '@/Config';
import ApiClient from '@/Modules/Shared/Utils/ApiClient';
import { RecaptchaVerifier, signInWithPhoneNumber, type ConfirmationResult } from 'firebase/auth';
import { auth } from '@/Firebase/Init';
import type { User } from '@/Modules/Auth/Types/User';

export default class AuthService {
  static getCsrf() {
    const api = ApiClient.create({
      baseURL: config.url
    });
    return api.get('/sanctum/csrf-cookie');
  }

  static getRecaptchaVerifier(containerId: string) {
    return new RecaptchaVerifier(auth, containerId, {
      size: 'invisible'
    });
  }

  static async sendVerificationCode(
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier
  ): Promise<ConfirmationResult> {
    return await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
  }

  static async loginWithToken(token: string) {
    const api = ApiClient.create({
      baseURL: config.url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return api.post('/login');
  }

  static async register(data: Record<string, any>, token: string) {
    const api = ApiClient.create({
      baseURL: config.url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return api.post('/register', data);
  }

  static async getAuthUser() {
    const api = ApiClient.create();
    return api.get('/users/authenticated');
  }

  static async logout() {
    const api = ApiClient.create({
      baseURL: config.url
    });
    return api.post('/logout');
  }

  static async updateUser(userData: User) {
    const api = ApiClient.create();
    return api.put('/users/profile', userData);
  }
}
