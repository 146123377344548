import { createApp } from 'vue';
import { createPinia } from 'pinia';
import type { App } from 'vue';
import { monitorAuthState } from '@/Modules/Auth/AuthStateMonitor';
import router from './Routes';

import './Assets/main.scss';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import Card from 'primevue/card';
import Select from 'primevue/select';

// ===== Plugins
import setupPrimeVue from '@/Plugins/PrimeVue/PrimeVue';

import index from './Index.vue';

import '@/Assets/main.scss';
import '@/Assets/styles.scss';
import '@/Assets/tailwind.css';

// Utility functions
const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    if (content !== null) {
      const re = /maximum-scale=[0-9.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  }
};

const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

const checkIsIOS = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

// Execute iOS-specific function if needed
if (checkIsIOS()) {
  disableIosTextFieldZoom();
}

import { useToast } from 'primevue/usetoast';

async function initApp() {
  await monitorAuthState();
  const app: App = createApp(index);
  setupPrimeVue(app);

  app.provide('useToast', useToast);

  app.component('Toast', Toast);
  app.use(router);

  app.use(createPinia());
  app.use(ToastService);

  // Add component registrations
  app.component('VSelect', Select);
  app.component('Card', Card);

  // Mount the app
  app.mount('#app');
}

// Start the app initialization
initApp();
