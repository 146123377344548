// src/firebase/init.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCZKVBUwceKqj9J-3nYxJO5zjBXirTkqCM',
  authDomain: 'pimenas-e060e.firebaseapp.com',
  projectId: 'pimenas-e060e',
  storageBucket: 'pimenas-e060e.appspot.com',
  messagingSenderId: '718441424618',
  appId: '1:718441424618:web:d9f2282ad9c6148f281993'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and export it for use in your components

export const auth = getAuth(app);
