import type { RouteRecordRaw } from 'vue-router';
import { defineAsyncComponent } from 'vue';
import { AuthMiddleware } from '@/Middlewares/AuthMiddleware';
import { GuestMiddleware } from '@/Middlewares/GuestMiddleware';
import { FirebaseAuthMiddleware } from '@/Middlewares/FirebaseAuthMiddleware';

const NotFound = defineAsyncComponent(() => import('@/Modules/Shared/Pages/NotFound.vue'));

const SharedRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('./Pages/Home.vue')
  },
  {
    path: '/start',
    component: () => import('./Pages/StepperLayout.vue'),
    children: [
      {
        path: '',
        name: 'stepper-selector',
        component: () => import('@/Modules/Listings/Components/Steppers/StepperSelector.vue')
      },
      {
        path: 'livestock/register',
        name: 'liveStock-register',
        component: () =>
          import('@/Modules/Listings/Components/Steppers/Livestock/LivestockStepper.vue')
      },
      {
        path: 'animal-feed/register',
        name: 'liveStock-register-feed',
        component: () =>
          import('@/Modules/Listings/Components/Steppers/AnimalFeed/AnimalFeedStepper.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/Layout/AppLayout.vue'),
    meta: {
      middlewares: [new AuthMiddleware()]
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboardHome',
        component: () => import('./Pages/Dashboard.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/Modules/Auth/Pages/Login.vue'),
    meta: {
      middlewares: [new GuestMiddleware('/profile')]
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/Modules/Auth/Pages/Register.vue'),
    meta: {
      middlewares: [new FirebaseAuthMiddleware('/profile'), new GuestMiddleware('/profile')]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/Modules/Auth/Pages/Profile.vue'),
    meta: {
      middlewares: [new AuthMiddleware()]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

export default SharedRoutes;
