import type { Middleware } from './Middleware';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useAuthStore } from '@/Modules/Auth/Stores/AuthStore';
import { storeToRefs } from 'pinia';

export class GuestMiddleware implements Middleware {
  constructor(private redirect: string = '/') {}

  async run(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    const authStore = useAuthStore();
    const { authenticated } = storeToRefs(authStore);

    await authStore.checkAuthStatus();

    if (authenticated.value) {
      next(this.redirect);
    }

    next();
  }
}
