// src/Middlewares/runMiddleware.ts
import type { Middleware } from './Middleware';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

export async function runMiddleware(
  middlewares: Middleware[],
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  for (const middleware of middlewares) {
    const result = await new Promise(resolve => {
      middleware.run(to, from, resolve);
    });

    if (result) {
      return next(result);
    }
  }

  next();
}
