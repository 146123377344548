import { defineStore, storeToRefs } from 'pinia';
import { ref, type Ref } from 'vue';
import AuthService from '@/Services/AuthService';
import { useFirebaseStore } from '@/Modules/Auth/Stores/FirebaseStore';
import type { User } from '@/Modules/Auth/Types/User';

export const createEmptyUser = (): User => ({
  id: null,
  uid: null,
  first_name: null,
  last_name: null,
  bio: null,
  avatar: null,
  preferred_language: null,
  timezone: null,
  phone_number: null,
  email: null,
  email_verified_at: null,
  last_login_at: null,
  created_at: null,
  updated_at: null,
  display_name: null,
  district: null,
  area: null,
  city: null,
  address: null,
  postal_code: null,
  country: null,
  date_of_birth: null,
  gender: null,
  business_sectors: [],
  website: null,
  social_media_links: {
    twitter: undefined,
    linkedin: undefined,
    facebook: undefined
  }
});

export const useAuthStore = defineStore('auth', () => {
  const firebaseStore = useFirebaseStore();
  const { accessToken } = storeToRefs(firebaseStore);

  const authenticated: Ref<boolean | null> = ref(null);
  const registered: Ref<boolean | null> = ref(null);
  const loggedIn: Ref<boolean> = ref(false);

  const lastChecked: Ref<number | null> = ref(null);
  const expirationTime = 2 * 1000;

  const user = ref<User>(createEmptyUser());

  const checkAuthStatus = async (): Promise<boolean> => {
    const now = Date.now();
    const secondsPassed = lastChecked.value ? now - lastChecked.value : expirationTime;

    if (!lastChecked.value || secondsPassed >= expirationTime) {
      await updateAuthStatus(now);
    }

    return authenticated.value ?? false;
  };

  const updateAuthStatus = async (timestamp: number) => {
    lastChecked.value = timestamp;

    try {
      const authUser = await AuthService.getAuthUser();
      user.value = authUser.data;
      authenticated.value = true;
      registered.value = true;
    } catch {
      // Silently handle the error
      user.value = createEmptyUser();
      authenticated.value = false;
      registered.value = false;
    }
  };

  const loginWithFirebaseToken = async (token: string) => {
    await AuthService.loginWithToken(token)
      .then(async response => {
        const responseCode = response.data.code ?? null;
        loggedIn.value = true;

        if (responseCode === 'USER_NOT_FOUND_LOCAL') {
          authenticated.value = false;
          registered.value = false;
          return Promise.resolve();
        }

        await checkAuthStatus();
      })
      .catch(error => {
        loggedIn.value = false;
        return Promise.reject(error.response.data);
      });
  };

  const register = async (user: User) => {
    try {
      const response = await AuthService.register(user, accessToken.value);
      return Promise.resolve(response.data);
    } catch (e: any) {
      return Promise.reject(e?.response?.data);
    }
  };

  const logout = async () => {
    await AuthService.logout();
    user.value = createEmptyUser();
    authenticated.value = false;
  };

  return {
    user,
    authenticated,
    registered,
    loggedIn,
    register,
    logout,
    loginWithFirebaseToken,
    updateAuthStatus,
    checkAuthStatus
  };
});
