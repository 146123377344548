import type { Middleware } from './Middleware';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { auth } from '@/Firebase/Init';
import { onAuthStateChanged } from 'firebase/auth';

export class FirebaseAuthMiddleware implements Middleware {
  constructor(
    private redirect: string | null = null,
    private authenticatedRedirect: string | null = null
  ) {}

  async run(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    onAuthStateChanged(auth, user => {
      if (user) {
        if (this.authenticatedRedirect) {
          next(this.authenticatedRedirect);
          return;
        }
      } else {
        if (this.redirect) {
          next(this.redirect);
          return;
        }
      }

      next();
    });
  }
}
